

.em-typography-line {
    padding-left: 25%;
    margin-bottom: 35px;
    position: relative;
    display: block;
    width: 100%;
  }
  
  .em-typography-line span {
    bottom: 10px;
    color: #000000;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    width: 260px;
    text-transform: none;
  }

  .em-p-dark {
      color:#000000;
      font-weight: 400;
  }
  .em-p-light {
      color:#ffffff;
      font-weight: 400;
  }

  .em-p-primary {
    color:#ef834d;
    font-weight: 400;
}


.card-video {
  position: relative;
}
  


@media (prefers-reduced-motion:reduce) {
  .carousel-control-next, .carousel-control-prev {
  transition: none;
}

}.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  text-decoration: none;
  outline: 0;
  opacity: .9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: no-repeat 50%/100% 100%}
  
/* change the fill color below to change the carousel arrow colors */
/* fill='%23ef834d' for PRIMARY COLOR */

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' fill='%2317a2b8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' fill='%2317a2b8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}


.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 5px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  /* change the background-color below to change the carousel pagination indicator; */
  background-color: #17a2b8;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .4;
  transition: opacity .6s ease;
}
@media (prefers-reduced-motion:reduce) {
  .carousel-indicators li {
  transition: none;
}
}.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

/*------------------------------------
 - COLOR em-em-primary
 ------------------------------------*/
 .alert-em-primary {
  color: #b1450f;
  background-color: #ff105108;
  border-color: #fef8f5;
}
.alert-em-primary hr {
  border-top-color: #fce8de;
}
.alert-em-primary .alert-link {
  color: #82330b;
}
.badge-em-primary {
  color: #212529;
  background-color: #ef834d;
}
.badge-em-primary[href]:hover, .badge-em-primary[href]:focus {
  color: #212529;
  background-color: #ea621e;
}
.bg-em-primary {
  background-color: #ef834d !important;
}
a.bg-em-primary:hover, a.bg-em-primary:focus, button.bg-em-primary:hover, button.bg-em-primary:focus {
  background-color: #ea621e !important;
}
.border-em-primary {
  border-color: #ef834d !important;
}
.btn-em-primary {
  color: #212529;
  background-color: #ef834d;
  border-color: #ef834d;
}
.btn-em-primary:hover {
  color: #212529;
  background-color: #ec6c2c;
  border-color: #ea621e;
}
.btn-em-primary:focus, .btn-em-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 77, 0.5);
}
.btn-em-primary.disabled, .btn-em-primary:disabled {
  color: #212529;
  background-color: #ef834d;
  border-color: #ef834d;
}
.btn-em-primary:not(:disabled):not(.disabled):active, .btn-em-primary:not(:disabled):not(.disabled).active, .show > .btn-em-primary.dropdown-toggle {
  color: #212529;
  background-color: #ea621e;
  border-color: #e55a14;
}
.btn-em-primary:not(:disabled):not(.disabled):active:focus, .btn-em-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-em-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 77, 0.5);
}
.btn-outline-em-primary {
  color: #ef834d;
  background-color: transparent;
  border-color: #ef834d;
}
.btn-outline-em-primary:hover {
  color: #212529;
  background-color: #ef834d;
  border-color: #ef834d;
}
.btn-outline-em-primary:focus, .btn-outline-em-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 77, 0.5);
}
.btn-outline-em-primary.disabled, .btn-outline-em-primary:disabled {
  color: #ef834d;
  background-color: transparent;
}
.btn-outline-em-primary:not(:disabled):not(.disabled):active, .btn-outline-em-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-em-primary.dropdown-toggle {
  color: #212529;
  background-color: #ef834d;
  border-color: #ef834d;
}
.btn-outline-em-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-em-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-em-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 77, 0.5);
}
.list-group-item-em-primary {
  color: #b1450f;
  background-color: #fef8f5;
}
.list-group-item-em-primary.list-group-item-action:hover, .list-group-item-em-primary.list-group-item-action:focus {
  color: #b1450f;
  background-color: #fce8de;
}
.list-group-item-em-primary.list-group-item-action.active {
  color: #212529;
  background-color: #b1450f;
  border-color: #b1450f;
}
.table-em-primary, .table-em-primary > th, .table-em-primary > td {
  background-color: #fef8f5;
}
.table-hover .table-em-primary:hover {
  background-color: #fce8de;
}
.table-hover .table-em-primary:hover > td, .table-hover .table-em-primary:hover > th {
  background-color: #fce8de;
}
.text-em-primary {
  color: #ef834d !important;
}
.text-em-bold-primary {
  color: #ef834d !important;
  font-weight: 600;
}

.text-em-bold-white {
  color: #ffffff !important;
  font-weight: 600;
}

a.text-em-primary:hover, a.text-em-primary:focus {
  color: #ea621e !important;
}


/*------------------------------------
- COLOR em-background
------------------------------------*/
.alert-em-background {
  color: #56b0d4;
  background-color: #15a129116;
  border-color: #14a122112;
}

.alert-em-background hr {
  border-top-color: #13611810c;
}

.alert-em-background .alert-link {
  color: #329bc5;
}

.badge-em-background {
  color: #212529;
  background-color: #b8deed;
}

.badge-em-background[href]:hover, .badge-em-background[href]:focus {
  color: #212529;
  background-color: #8fcbe2;
}

.bg-em-background {
  background-color: #b8deed !important;
}

a.bg-em-background:hover, a.bg-em-background:focus,
button.bg-em-background:hover,
button.bg-em-background:focus {
  background-color: #8fcbe2 !important;
}

.border-em-background {
  border-color: #b8deed !important;
}

.btn-em-background {
  color: #212529;
  background-color: #b8deed;
  border-color: #b8deed;
}

.btn-em-background:hover {
  color: #212529;
  background-color: #9bd0e5;
  border-color: #8fcbe2;
}

.btn-em-background:focus, .btn-em-background.focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 222, 237, 0.5);
}

.btn-em-background.disabled, .btn-em-background:disabled {
  color: #212529;
  background-color: #b8deed;
  border-color: #b8deed;
}

.btn-em-background:not(:disabled):not(.disabled):active, .btn-em-background:not(:disabled):not(.disabled).active, .show > .btn-em-background.dropdown-toggle {
  color: #212529;
  background-color: #8fcbe2;
  border-color: #83c5df;
}

.btn-em-background:not(:disabled):not(.disabled):active:focus, .btn-em-background:not(:disabled):not(.disabled).active:focus, .show > .btn-em-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 222, 237, 0.5);
}

.btn-outline-em-background {
  color: #b8deed;
  background-color: transparent;
  border-color: #b8deed;
}

.btn-outline-em-background:hover {
  color: #212529;
  background-color: #b8deed;
  border-color: #b8deed;
}

.btn-outline-em-background:focus, .btn-outline-em-background.focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 222, 237, 0.5);
}

.btn-outline-em-background.disabled, .btn-outline-em-background:disabled {
  color: #b8deed;
  background-color: transparent;
}

.btn-outline-em-background:not(:disabled):not(.disabled):active, .btn-outline-em-background:not(:disabled):not(.disabled).active, .show > .btn-outline-em-background.dropdown-toggle {
  color: #212529;
  background-color: #b8deed;
  border-color: #b8deed;
}

.btn-outline-em-background:not(:disabled):not(.disabled):active:focus, .btn-outline-em-background:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-em-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 222, 237, 0.5);
}

.list-group-item-em-background {
  color: #56b0d4;
  background-color: #14a122112;
}

.list-group-item-em-background.list-group-item-action:hover, .list-group-item-em-background.list-group-item-action:focus {
  color: #56b0d4;
  background-color: #13611810c;
}

.list-group-item-em-background.list-group-item-action.active {
  color: #212529;
  background-color: #56b0d4;
  border-color: #56b0d4;
}

.table-em-background,
.table-em-background > th,
.table-em-background > td {
  background-color: #14a122112;
}

.table-hover .table-em-background:hover {
  background-color: #13611810c;
}

.table-hover .table-em-background:hover > td,
.table-hover .table-em-background:hover > th {
  background-color: #13611810c;
}

.text-em-background {
  color: #b8deed !important;
}

a.text-em-background:hover, a.text-em-background:focus {
  color: #8fcbe2 !important;
}
